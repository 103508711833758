import { makeAutoObservable } from 'mobx';
import agent from '../api/agent';
import { CoreValues } from '../models/coreValues';
import { CourseOutline } from '../models/courseOutline';

export default class CoreDataStore {
	coreValues: CoreValues[] = [];
	courseOutline: CourseOutline[] = [];

	constructor() {
		makeAutoObservable(this);
	}

	// 	loadCoreValues = async () => {
	// 		try {
	// 			const coreValues = await agent.CoreData.coreValues();
	// 			coreValues.forEach((coreValue) => {
	// 				this.coreValues.push(coreValue);
	// 			});
	// 		} catch (error) {
	// 			console.log(error);
	// 		}
	// 	};

	// 	loadCourseOutline = async () => {
	// 		try {
	// 			const courseOutline = await agent.CoreData.courseOutline();
	// 			courseOutline.forEach((courseOutline) => {
	// 				this.courseOutline.push(courseOutline);
	// 			})
	// 		} catch (error) {
	// 			console.log(error)
	// 		}
	// 	};
}
