import { Button, Header, Icon, Segment } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const NotFound = () => {
	return (
		<Segment placeholder>
			<Header icon>
				<Icon name='search' />
				Oops - We've looked everywhere and cound not find this.
			</Header>
			<Segment.Inline>
				<Button as={Link} to='/' primary>
					Return to Home page
				</Button>
			</Segment.Inline>
		</Segment>
	);
};

export default NotFound;
