import { CoreValues } from "../../../../app/models/coreValues";
import './CoreValues.style.css'

interface Props {
	coreV: CoreValues;
}

const CoreValueItem = (coreValues: Props) => {
	let sideSelector;
	let titleContainerSide;
	let contentContainerSide;
	let backNumberSide;

	function reveal() {
		var reveals = document.querySelectorAll('.reveal');

		for (var i = 0; i < reveals.length; i++) {
			var windowHeight = window.innerHeight;
			var elementTop = reveals[i].getBoundingClientRect().top;
			var elementVisible = 150;

			if (elementTop < windowHeight - elementVisible) {
				reveals[i].classList.add('active');
			} else {
				reveals[i].classList.remove('active');
			}
		}
	}

	window.addEventListener('scroll', reveal);

	if (coreValues.coreV.id % 2) {
		sideSelector = 'cvContainerLeft reveal';
		titleContainerSide = 'cvTitleContainerLeft';
		contentContainerSide = 'cvContentContainerLeft';
		backNumberSide = 'cvBackNumberContainerLeft';
	} else {
		sideSelector = 'cvContainerRight reveal';
		titleContainerSide = 'cvTitleContainerRight';
		contentContainerSide = 'cvContentContainerRight';
		backNumberSide = 'cvBackNumberContainerRight';
	}

	return (
		<div className={sideSelector}>
			<div className={backNumberSide}>
				<p className='cvBackNumber'>{coreValues.coreV.number}</p>
			</div>
			<div className={titleContainerSide}>
				<p className='cvTitle'>{coreValues.coreV.title}</p>
			</div>
			<div className={contentContainerSide}>
				<p className='cvContent'>{coreValues.coreV.content}</p>
			</div>
		</div>
	);
};
export default CoreValueItem;