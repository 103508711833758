export const countryOption = [
	{ key: 'GB', value: 'United Kingdom', text: 'United Kingdom' },
	{ key: 'AF', value: 'Afghanistan', text: 'Afghanistan' },
	{ key: 'AX', value: 'Aland Islands', text: 'Aland Islands' },
	{ key: 'AL', value: 'Albania', text: 'Albania' },
	{ key: 'DZ', value: 'Algeria', text: 'Algeria' },
	{ key: 'AS', value: 'American Samoa', text: 'American Samoa' },
	{ key: 'AD', value: 'Andorra', text: 'Andorra' },
	{ key: 'AO', value: 'Angola', text: 'Angola' },
	{ key: 'AI', value: 'Anguilla', text: 'Anguilla' },
	{ key: 'AQ', value: 'Antarctica', text: 'Antarctica' },
	{ key: 'AG', value: 'Antigua and Barbuda', text: 'Antigua and Barbuda' },
	{ key: 'AR', value: 'Argentina', text: 'Argentina' },
	{ key: 'AM', value: 'Armenia', text: 'Armenia' },
	{ key: 'AW', value: 'Aruba', text: 'Aruba' },
	{ key: 'AU', value: 'Australia', text: 'Australia' },
	{ key: 'AT', value: 'Austria', text: 'Austria' },
	{ key: 'AZ', value: 'Azerbaijan', text: 'Azerbaijan' },
	{ key: 'BS', value: 'Bahamas', text: 'Bahamas' },
	{ key: 'BH', value: 'Bahrain', text: 'Bahrain' },
	{ key: 'BD', value: 'Bangladesh', text: 'Bangladesh' },
	{ key: 'BB', value: 'Barbados', text: 'Barbados' },
	{ key: 'BY', value: 'Belarus', text: 'Belarus' },
	{ key: 'BE', value: 'Belgium', text: 'Belgium' },
	{ key: 'BZ', value: 'Belize', text: 'Belize' },
	{ key: 'BJ', value: 'Benin', text: 'Benin' },
	{ key: 'BM', value: 'Bermuda', text: 'Bermuda' },
	{ key: 'BT', value: 'Bhutan', text: 'Bhutan' },
	{ key: 'BO', value: 'Bolivia', text: 'Bolivia' },
	{
		key: 'BQ',
		value: 'Bonaire, Sint Eustatius and Saba',
		text: 'Bonaire, Sint Eustatius and Saba',
	},
	{
		key: 'BA',
		value: 'Bosnia and Herzegovina',
		text: 'Bosnia and Herzegovina',
	},
	{ key: 'BW', value: 'Botswana', text: 'Botswana' },
	{ key: 'BV', value: 'Bouvet Island', text: 'Bouvet Island' },
	{ key: 'BR', value: 'Brazil', text: 'Brazil' },
	{
		key: 'IO',
		value: 'British Indian Ocean Territory',
		text: 'British Indian Ocean Territory',
	},
	{ key: 'BN', value: 'Brunei Darussalam', text: 'Brunei Darussalam' },
	{ key: 'BG', value: 'Bulgaria', text: 'Bulgaria' },
	{ key: 'BF', value: 'Burkina Faso', text: 'Burkina Faso' },
	{ key: 'BI', value: 'Burundi', text: 'Burundi' },
	{ key: 'KH', value: 'Cambodia', text: 'Cambodia' },
	{ key: 'CM', value: 'Cameroon', text: 'Cameroon' },
	{ key: 'CA', value: 'Canada', text: 'Canada' },
	{ key: 'CV', value: 'Cape Verde', text: 'Cape Verde' },
	{ key: 'KY', value: 'Cayman Islands', text: 'Cayman Islands' },
	{
		key: 'CF',
		value: 'Central African Republic',
		text: 'Central African Republic',
	},
	{ key: 'TD', value: 'Chad', text: 'Chad' },
	{ key: 'CL', value: 'Chile', text: 'Chile' },
	{ key: 'CN', value: 'China', text: 'China' },
	{ key: 'CX', value: 'Christmas Island', text: 'Christmas Island' },
	{
		key: 'CC',
		value: 'Cocos (Keeling) Islands',
		text: 'Cocos (Keeling) Islands',
	},
	{ key: 'CO', value: 'Colombia', text: 'Colombia' },
	{ key: 'KM', value: 'Comoros', text: 'Comoros' },
	{ key: 'CG', value: 'Congo', text: 'Congo' },
	{
		key: 'CD',
		value: 'Congo, Democratic Republic of the Congo',
		text: 'Congo, Democratic Republic of the Congo',
	},
	{ key: 'CK', value: 'Cook Islands', text: 'Cook Islands' },
	{ key: 'CR', value: 'Costa Rica', text: 'Costa Rica' },
	{ key: 'CI', value: "Cote D'Ivoire", text: "Cote D'Ivoire" },
	{ key: 'HR', value: 'Croatia', text: 'Croatia' },
	{ key: 'CU', value: 'Cuba', text: 'Cuba' },
	{ key: 'CW', value: 'Curacao', text: 'Curacao' },
	{ key: 'CY', value: 'Cyprus', text: 'Cyprus' },
	{ key: 'CZ', value: 'Czech Republic', text: 'Czech Republic' },
	{ key: 'DK', value: 'Denmark', text: 'Denmark' },
	{ key: 'DJ', value: 'Djibouti', text: 'Djibouti' },
	{ key: 'DM', value: 'Dominica', text: 'Dominica' },
	{ key: 'DO', value: 'Dominican Republic', text: 'Dominican Republic' },
	{ key: 'EC', value: 'Ecuador', text: 'Ecuador' },
	{ key: 'EG', value: 'Egypt', text: 'Egypt' },
	{ key: 'SV', value: 'El Salvador', text: 'El Salvador' },
	{ key: 'GQ', value: 'Equatorial Guinea', text: 'Equatorial Guinea' },
	{ key: 'ER', value: 'Eritrea', text: 'Eritrea' },
	{ key: 'EE', value: 'Estonia', text: 'Estonia' },
	{ key: 'ET', value: 'Ethiopia', text: 'Ethiopia' },
	{
		key: 'FK',
		value: 'Falkland Islands (Malvinas)',
		text: 'Falkland Islands (Malvinas)',
	},
	{ key: 'FO', value: 'Faroe Islands', text: 'Faroe Islands' },
	{ key: 'FJ', value: 'Fiji', text: 'Fiji' },
	{ key: 'FI', value: 'Finland', text: 'Finland' },
	{ key: 'FR', value: 'France', text: 'France' },
	{ key: 'GF', value: 'French Guiana', text: 'French Guiana' },
	{ key: 'PF', value: 'French Polynesia', text: 'French Polynesia' },
	{
		key: 'TF',
		value: 'French Southern Territories',
		text: 'French Southern Territories',
	},
	{ key: 'GA', value: 'Gabon', text: 'Gabon' },
	{ key: 'GM', value: 'Gambia', text: 'Gambia' },
	{ key: 'GE', value: 'Georgia', text: 'Georgia' },
	{ key: 'DE', value: 'Germany', text: 'Germany' },
	{ key: 'GH', value: 'Ghana', text: 'Ghana' },
	{ key: 'GI', value: 'Gibraltar', text: 'Gibraltar' },
	{ key: 'GR', value: 'Greece', text: 'Greece' },
	{ key: 'GL', value: 'Greenland', text: 'Greenland' },
	{ key: 'GD', value: 'Grenada', text: 'Grenada' },
	{ key: 'GP', value: 'Guadeloupe', text: 'Guadeloupe' },
	{ key: 'GU', value: 'Guam', text: 'Guam' },
	{ key: 'GT', value: 'Guatemala', text: 'Guatemala' },
	{ key: 'GG', value: 'Guernsey', text: 'Guernsey' },
	{ key: 'GN', value: 'Guinea', text: 'Guinea' },
	{ key: 'GW', value: 'Guinea-Bissau', text: 'Guinea-Bissau' },
	{ key: 'GY', value: 'Guyana', text: 'Guyana' },
	{ key: 'HT', value: 'Haiti', text: 'Haiti' },
	{
		key: 'HM',
		value: 'Heard Island and Mcdonald Islands',
		text: 'Heard Island and Mcdonald Islands',
	},
	{
		key: 'VA',
		value: 'Holy See (Vatican City State)',
		text: 'Holy See (Vatican City State)',
	},
	{ key: 'HN', value: 'Honduras', text: 'Honduras' },
	{ key: 'HK', value: 'Hong Kong', text: 'Hong Kong' },
	{ key: 'HU', value: 'Hungary', text: 'Hungary' },
	{ key: 'IS', value: 'Iceland', text: 'Iceland' },
	{ key: 'IN', value: 'India', text: 'India' },
	{ key: 'ID', value: 'Indonesia', text: 'Indonesia' },
	{
		key: 'IR',
		value: 'Iran, Islamic Republic of',
		text: 'Iran, Islamic Republic of',
	},
	{ key: 'IQ', value: 'Iraq', text: 'Iraq' },
	{ key: 'IE', value: 'Ireland', text: 'Ireland' },
	{ key: 'IM', value: 'Isle of Man', text: 'Isle of Man' },
	{ key: 'IL', value: 'Israel', text: 'Israel' },
	{ key: 'IT', value: 'Italy', text: 'Italy' },
	{ key: 'JM', value: 'Jamaica', text: 'Jamaica' },
	{ key: 'JP', value: 'Japan', text: 'Japan' },
	{ key: 'JE', value: 'Jersey', text: 'Jersey' },
	{ key: 'JO', value: 'Jordan', text: 'Jordan' },
	{ key: 'KZ', value: 'Kazakhstan', text: 'Kazakhstan' },
	{ key: 'KE', value: 'Kenya', text: 'Kenya' },
	{ key: 'KI', value: 'Kiribati', text: 'Kiribati' },
	{
		key: 'KP',
		value: "Korea, Democratic People's Republic of",
		text: "Korea, Democratic People's Republic of",
	},
	{ key: 'KR', value: 'Korea, Republic of', text: 'Korea, Republic of' },
	{ key: 'XK', value: 'Kosovo', text: 'Kosovo' },
	{ key: 'KW', value: 'Kuwait', text: 'Kuwait' },
	{ key: 'KG', value: 'Kyrgyzstan', text: 'Kyrgyzstan' },
	{
		key: 'LA',
		value: "Lao People's Democratic Republic",
		text: "Lao People's Democratic Republic",
	},
	{ key: 'LV', value: 'Latvia', text: 'Latvia' },
	{ key: 'LB', value: 'Lebanon', text: 'Lebanon' },
	{ key: 'LS', value: 'Lesotho', text: 'Lesotho' },
	{ key: 'LR', value: 'Liberia', text: 'Liberia' },
	{
		key: 'LY',
		value: 'Libyan Arab Jamahiriya',
		text: 'Libyan Arab Jamahiriya',
	},
	{ key: 'LI', value: 'Liechtenstein', text: 'Liechtenstein' },
	{ key: 'LT', value: 'Lithuania', text: 'Lithuania' },
	{ key: 'LU', value: 'Luxembourg', text: 'Luxembourg' },
	{ key: 'MO', value: 'Macao', text: 'Macao' },
	{
		key: 'MK',
		value: 'Macedonia,he Former Yugoslav Republic of',
		text: 'Macedonia,he Former Yugoslav Republic of',
	},
	{ key: 'MG', value: 'Madagascar', text: 'Madagascar' },
	{ key: 'MW', value: 'Malawi', text: 'Malawi' },
	{ key: 'MY', value: 'Malaysia', text: 'Malaysia' },
	{ key: 'MV', value: 'Maldives', text: 'Maldives' },
	{ key: 'ML', value: 'Mali', text: 'Mali' },
	{ key: 'MT', value: 'Malta', text: 'Malta' },
	{ key: 'MH', value: 'Marshall Islands', text: 'Marshall Islands' },
	{ key: 'MQ', value: 'Martinique', text: 'Martinique' },
	{ key: 'MR', value: 'Mauritania', text: 'Mauritania' },
	{ key: 'MU', value: 'Mauritius', text: 'Mauritius' },
	{ key: 'YT', value: 'Mayotte', text: 'Mayotte' },
	{ key: 'MX', value: 'Mexico', text: 'Mexico' },
	{
		key: 'FM',
		value: 'Micronesia, Federated States of',
		text: 'Micronesia, Federated States of',
	},
	{ key: 'MD', value: 'Moldova, Republic of', text: 'Moldova, Republic of' },
	{ key: 'MC', value: 'Monaco', text: 'Monaco' },
	{ key: 'MN', value: 'Mongolia', text: 'Mongolia' },
	{ key: 'ME', value: 'Montenegro', text: 'Montenegro' },
	{ key: 'MS', value: 'Montserrat', text: 'Montserrat' },
	{ key: 'MA', value: 'Morocco', text: 'Morocco' },
	{ key: 'MZ', value: 'Mozambique', text: 'Mozambique' },
	{ key: 'MM', value: 'Myanmar', text: 'Myanmar' },
	{ key: 'NA', value: 'Namibia', text: 'Namibia' },
	{ key: 'NR', value: 'Nauru', text: 'Nauru' },
	{ key: 'NP', value: 'Nepal', text: 'Nepal' },
	{ key: 'NL', value: 'Netherlands', text: 'Netherlands' },
	{ key: 'AN', value: 'Netherlands Antilles', text: 'Netherlands Antilles' },
	{ key: 'NC', value: 'New Caledonia', text: 'New Caledonia' },
	{ key: 'NZ', value: 'New Zealand', text: 'New Zealand' },
	{ key: 'NI', value: 'Nicaragua', text: 'Nicaragua' },
	{ key: 'NE', value: 'Niger', text: 'Niger' },
	{ key: 'NG', value: 'Nigeria', text: 'Nigeria' },
	{ key: 'NU', value: 'Niue', text: 'Niue' },
	{ key: 'NF', value: 'Norfolk Island', text: 'Norfolk Island' },
	{
		key: 'MP',
		value: 'Northern Mariana Islands',
		text: 'Northern Mariana Islands',
	},
	{ key: 'NO', value: 'Norway', text: 'Norway' },
	{ key: 'OM', value: 'Oman', text: 'Oman' },
	{ key: 'PK', value: 'Pakistan', text: 'Pakistan' },
	{ key: 'PW', value: 'Palau', text: 'Palau' },
	{
		key: 'PS',
		value: 'Palestinian Territory, Occupied',
		text: 'Palestinian Territory, Occupied',
	},
	{ key: 'PA', value: 'Panama', text: 'Panama' },
	{ key: 'PG', value: 'Papua New Guinea', text: 'Papua New Guinea' },
	{ key: 'PY', value: 'Paraguay', text: 'Paraguay' },
	{ key: 'PE', value: 'Peru', text: 'Peru' },
	{ key: 'PH', value: 'Philippines', text: 'Philippines' },
	{ key: 'PN', value: 'Pitcairn', text: 'Pitcairn' },
	{ key: 'PL', value: 'Poland', text: 'Poland' },
	{ key: 'PT', value: 'Portugal', text: 'Portugal' },
	{ key: 'PR', value: 'Puerto Rico', text: 'Puerto Rico' },
	{ key: 'QA', value: 'Qatar', text: 'Qatar' },
	{ key: 'RE', value: 'Reunion', text: 'Reunion' },
	{ key: 'RO', value: 'Romania', text: 'Romania' },
	{ key: 'RU', value: 'Russian Federation', text: 'Russian Federation' },
	{ key: 'RW', value: 'Rwanda', text: 'Rwanda' },
	{ key: 'BL', value: 'Saint Barthelemy', text: 'Saint Barthelemy' },
	{ key: 'SH', value: 'Saint Helena', text: 'Saint Helena' },
	{ key: 'KN', value: 'Saint Kitts and Nevis', text: 'Saint Kitts and Nevis' },
	{ key: 'LC', value: 'Saint Lucia', text: 'Saint Lucia' },
	{ key: 'MF', value: 'Saint Martin', text: 'Saint Martin' },
	{
		key: 'PM',
		value: 'Saint Pierre and Miquelon',
		text: 'Saint Pierre and Miquelon',
	},
	{
		key: 'VC',
		value: 'Saint Vincent and the Grenadines',
		text: 'Saint Vincent and the Grenadines',
	},
	{ key: 'WS', value: 'Samoa', text: 'Samoa' },
	{ key: 'SM', value: 'San Marino', text: 'San Marino' },
	{ key: 'ST', value: 'Sao Tome and Principe', text: 'Sao Tome and Principe' },
	{ key: 'SA', value: 'Saudi Arabia', text: 'Saudi Arabia' },
	{ key: 'SN', value: 'Senegal', text: 'Senegal' },
	{ key: 'RS', value: 'Serbia', text: 'Serbia' },
	{ key: 'CS', value: 'Serbia and Montenegro', text: 'Serbia and Montenegro' },
	{ key: 'SC', value: 'Seychelles', text: 'Seychelles' },
	{ key: 'SL', value: 'Sierra Leone', text: 'Sierra Leone' },
	{ key: 'SG', value: 'Singapore', text: 'Singapore' },
	{ key: 'SX', value: 'Sint Maarten', text: 'Sint Maarten' },
	{ key: 'SK', value: 'Slovakia', text: 'Slovakia' },
	{ key: 'SI', value: 'Slovenia', text: 'Slovenia' },
	{ key: 'SB', value: 'Solomon Islands', text: 'Solomon Islands' },
	{ key: 'SO', value: 'Somalia', text: 'Somalia' },
	{ key: 'ZA', value: 'South Africa', text: 'South Africa' },
	{
		key: 'GS',
		value: 'South Georgia and the South Sandwich Islands',
		text: 'South Georgia and the South Sandwich Islands',
	},
	{ key: 'SS', value: 'South Sudan', text: 'South Sudan' },
	{ key: 'ES', value: 'Spain', text: 'Spain' },
	{ key: 'LK', value: 'Sri Lanka', text: 'Sri Lanka' },
	{ key: 'SD', value: 'Sudan', text: 'Sudan' },
	{ key: 'SR', value: 'Suriname', text: 'Suriname' },
	{
		key: 'SJ',
		value: 'Svalbard and Jan Mayen',
		text: 'Svalbard and Jan Mayen',
	},
	{ key: 'SZ', value: 'Swaziland', text: 'Swaziland' },
	{ key: 'SE', value: 'Sweden', text: 'Sweden' },
	{ key: 'CH', value: 'Switzerland', text: 'Switzerland' },
	{ key: 'SY', value: 'Syrian Arab Republic', text: 'Syrian Arab Republic' },
	{ key: 'TW', value: 'Taiwan', text: 'Taiwan' },
	{ key: 'TJ', value: 'Tajikistan', text: 'Tajikistan' },
	{
		key: 'TZ',
		value: 'Tanzania, United Republic of',
		text: 'Tanzania, United Republic of',
	},
	{ key: 'TH', value: 'Thailand', text: 'Thailand' },
	{ key: 'TL', value: 'Timor-Leste', text: 'Timor-Leste' },
	{ key: 'TG', value: 'Togo', text: 'Togo' },
	{ key: 'TK', value: 'Tokelau', text: 'Tokelau' },
	{ key: 'TO', value: 'Tonga', text: 'Tonga' },
	{ key: 'TT', value: 'Trinidad and Tobago', text: 'Trinidad and Tobago' },
	{ key: 'TN', value: 'Tunisia', text: 'Tunisia' },
	{ key: 'TR', value: 'Turkey', text: 'Turkey' },
	{ key: 'TM', value: 'Turkmenistan', text: 'Turkmenistan' },
	{
		key: 'TC',
		value: 'Turks and Caicos Islands',
		text: 'Turks and Caicos Islands',
	},
	{ key: 'TV', value: 'Tuvalu', text: 'Tuvalu' },
	{ key: 'UG', value: 'Uganda', text: 'Uganda' },
	{ key: 'UA', value: 'Ukraine', text: 'Ukraine' },
	{ key: 'AE', value: 'United Arab Emirates', text: 'United Arab Emirates' },
	{ key: 'US', value: 'United States', text: 'United States' },
	{
		key: 'UM',
		value: 'United States Minor Outlying Islands',
		text: 'United States Minor Outlying Islands',
	},
	{ key: 'UY', value: 'Uruguay', text: 'Uruguay' },
	{ key: 'UZ', value: 'Uzbekistan', text: 'Uzbekistan' },
	{ key: 'VU', value: 'Vanuatu', text: 'Vanuatu' },
	{ key: 'VE', value: 'Venezuela', text: 'Venezuela' },
	{ key: 'VN', value: 'Viet Nam', text: 'Viet Nam' },
	{
		key: 'VG',
		value: 'Virgin Islands, British',
		text: 'Virgin Islands, British',
	},
	{ key: 'VI', value: 'Virgin Islands, U.s.', text: 'Virgin Islands, U.s.' },
	{ key: 'WF', value: 'Wallis and Futuna', text: 'Wallis and Futuna' },
	{ key: 'EH', value: 'Western Sahara', text: 'Western Sahara' },
	{ key: 'YE', value: 'Yemen', text: 'Yemen' },
	{ key: 'ZM', value: 'Zambia', text: 'Zambia' },
	{ key: 'ZW', value: 'Zimbabwe', text: 'Zimbabwe' },
];
