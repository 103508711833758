import { Container } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { Routes, Route, useLocation, Outlet } from 'react-router-dom';
import Home from '../../app/pages/home/Home';
import TestErrors from '../../features/errors/TestError';
import { ToastContainer } from 'react-toastify';
import NotFound from '../../features/errors/NotFound';
import ServerError from '../../features/errors/ServerError';
import Footer from '../../features/components/footer/Footer';
import NavBar from './NavBar';

function App() {
	const AppLayout = () => {
		return (
			<>
				<Container style={{ marginTop: '7em' }}>
					<NavBar />
					<Outlet />
				</Container>
			</>
		);
	};

	const location = useLocation();

	return (
		<>
				<ToastContainer position='bottom-right' hideProgressBar />
				<Routes>
					<Route path='/' element={<Home />} />
					<Route element={<AppLayout />}>
						<Route path='/errors' element={<TestErrors />} />
						<Route path='/server-error' element={<ServerError />} />
						<Route path='*' element={<NotFound />} />
					</Route>
				</Routes>
			<Footer />
		</>
	);
}

export default observer(App);
