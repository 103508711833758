import { makeAutoObservable } from 'mobx';
import { ServerError } from '../models/serverError';

export default class CommonStore {
	error: ServerError | null = null;
	formOpen = false;
	showSuccessModal = false;
	showErrorModal = false;

	constructor() {
		makeAutoObservable(this);
	}

	setServerError = (error: ServerError) => {
		this.error = error;
	};

	setShowSuccess = (state:boolean) => {
		this.showSuccessModal = state;
	};

	setShowError = (state: boolean) => {
		this.showErrorModal = state;
	}
	setFormOpen = (state: boolean) => {
		this.formOpen = state;
	}
}
