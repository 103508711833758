import { HeaderStyle } from './Header-Style';
import logo from '../../../assets/newlogos/JE-Logo-04.svg';
import { Link } from 'react-router-dom';
// import { Link as LinkScroll } from 'react-scroll';
import './Header.style.css';

const Header = () => {
	if (window.matchMedia('(min-width:600px)').matches) {
		return (
			<HeaderStyle className='navbar sticky-top flex-md-nowrap p-0'>
				<div className='container'>
					{/* <ul></ul> */}
					<Link to='/'>
						<img src={logo} alt='Journey Equip' />
					</Link>
					{/* <ul>
						<li>
							<a
							// to='courseOutlineSection'
							// activeClass='active'
							// spy={true}
							// smooth={true}
							>
								Course
							</a>
						</li>
						<li>
							<a
							// to='aboutUsSection'
							// activeClass='active'
							// spy={true}
							// smooth={true}
							>
								About Us
							</a>
						</li>
					</ul> */}
				</div>
			</HeaderStyle>
		);
	} else {
		return (
			<HeaderStyle className='navbar sticky-top flex-md-nowrap p-0'>
				<div className='container'>
					<Link to='/'>
						<img src={logo} alt='Journey Equip' />
					</Link>
				</div>
			</HeaderStyle>
		);
	}
};

export default Header;
