import { useField } from 'formik';
import React from 'react';
import { Form, Label, Select } from 'semantic-ui-react';
import './MySelectInput.style.css';

interface Props {
	placeholder: string;
	name: string;
	options: any;
	label?: string;
	defaultValue?: any;
}

export default function MySelectInput(props: Props) {
	const [field, meta, helpers] = useField(props.name);
	return (
		<Form.Field
			className='selectFieldWrapper'
			error={meta.touched && !!meta.error}
		>
			<label>{props.label}</label>
			<Select
				className='selectField'
				clearable
				options={props.options}
				value={field.value || null}
				onChange={(e, d) => helpers.setValue(d.value)}
				onBlur={() => helpers.setTouched(true)}
				placeholder={props.placeholder}
			/>
			{meta.touched && meta.error ? (
				<Label basic color='red'>
					{meta.error}
				</Label>
			) : null}
		</Form.Field>
	);
}
