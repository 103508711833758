import { useStore } from '../../../../app/stores/store';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';

// const style = {
// 	position: 'absolute',
// 	top: '50%',
// 	left: '50%',
// 	transform: 'translate(-50%, -50%)',
// 	width: 400,
// 	bgcolor: 'background.paper',
// 	border: '2px solid #000',
// 	boxShadow: 24,
// 	p: 4,
// 	fontfamily: 'articulat-cf',
// 	fontStyle: 'normal',
// 	fontWeight: 300,
// 	fontSize: '18px',
// };
interface Props {
	name: string;
}

export default observer(function SuccessModal({ name }: Props) {
	const { commonStore } = useStore();
	const { showSuccessModal, setShowSuccess } = commonStore;
	const handleClose = () => setShowSuccess(false);

	return (
		<Modal
			onClose={handleClose}
			onOpen={() => setShowSuccess(true)}
			open={showSuccessModal}
		>
			<Modal.Header>Thanks for Showing Interest</Modal.Header>
			<Modal.Content>
				We will be sending you a full application form for Journey Equip to the
				email you provided very soon! If you do not hear from us or have any
				more questions, please drop us a message at:
				<br />
				<a href='mailto:hello@journeyequip.com'>hello@journeyequip.com</a>
				<br />
				We look forward to meeting you.
				<br />
				<br /> Journey Equip Admissions.
			</Modal.Content>
			<Modal.Actions>
				<Button
					color='orange'
					labelPosition='right'
					icon
					onClick={() => setShowSuccess(false)}
				>
					Close <Icon name='x' />
				</Button>
			</Modal.Actions>
		</Modal>
	);
});
