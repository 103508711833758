import { useStore } from '../../../../app/stores/store';
import { Button, Icon, Modal } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';

// const style = {
// 	position: 'absolute',
// 	top: '50%',
// 	left: '50%',
// 	transform: 'translate(-50%, -50%)',
// 	width: 400,
// 	bgcolor: 'background.paper',
// 	border: '2px solid #000',
// 	boxShadow: 24,
// 	p: 4,
// 	fontfamily: 'articulat-cf',
// 	fontStyle: 'normal',
// 	fontWeight: 300,
// 	fontSize: '18px',
// };

export default observer(function ErrorModal() {
	const { commonStore } = useStore();
	const { showErrorModal, setShowError } = commonStore;
	const handleClose = () => setShowError(false);

	return (
		<Modal
			onClose={handleClose}
			onOpen={() => setShowError(true)}
			open={showErrorModal}
		>
			<Modal.Header>Error</Modal.Header>
			<Modal.Content>
				An Error Occured in the sending of your details to start the admissions
				process, please try again. If this continues to happen please drop us a
				message at:
				<br />
				<a href='mailto:hello@journeyequip.com'>hello@journeyequip.com</a>
				<br />
				<br /> Journey Equip Admissions.
			</Modal.Content>
			<Modal.Actions>
				<Button
					color='orange'
					labelPosition='right'
					icon
					onClick={() => setShowError(false)}
				>
					Close <Icon name='x' />
				</Button>
			</Modal.Actions>
		</Modal>
	);
});
