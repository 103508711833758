import { Container, Menu } from 'semantic-ui-react';
import logo from '../../assets/newlogos/JE-Logo-02.svg';
import '../styles/NavBar.style.css';

const NavBar = () => {
	return (
		<Menu inverted fixed='top'>
			<Container>
				<Menu.Item header style={{ width: '10em' }}>
					<img src={logo} alt='logo' style={{ width: '10em' }} />
				</Menu.Item>
				<Menu.Item name='Apply' style={{ color: '#f4a306' }} />

				<Menu.Item name='Login' style={{ color: '#f4a306' }} />
			</Container>
		</Menu>
	);
};
export default NavBar;
