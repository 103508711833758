import { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import './interestForm.style.css';
import agent from '../../../../app/api/agent';
import ErrorModal from '../../common/card/ErrorModal';
import SuccessModal from '../../common/card/SuccessModal';
import { countryOption } from '../../../../app/valuesData/countryList';
import { v4 as uuid } from 'uuid';
import { useStore } from '../../../../app/stores/store';
import MyTextInput from '../../common/form/MyTextInput';
import MySelectInput from '../../common/form/MySelectInput';
import { observer } from 'mobx-react-lite';
import { Button } from 'semantic-ui-react';
import ValidationErrors from '../../../errors/ValidationErrors';
import { InterestFormData } from '../../../../app/models/interestForm';
import { Id } from 'react-toastify';

const placeholders = {
	name: "What's Your Name",
	email: 'Email Address',
	country: 'What Country Are You From',
};
interface Props {
	borderLine?: string;
}
const InterestForm = ({ borderLine }: Props) => {
	const { commonStore } = useStore();
	const {
		formOpen,
		setFormOpen,
		showErrorModal,
		setShowError,
		showSuccessModal,
		setShowSuccess,
	} = commonStore;
	const [display, setDisplay] = useState('none');
	const [applyButtonText, setApplyButtonText] = useState('Register Interest');
	const [submitButtonText, setSubmitButtonText] = useState('Submit');
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState('');

	// const borderline = { ...borderLine };
	// const borderlineDecon = borderline;

	const borderClassname = 'smallScreen ' + borderLine;

	useEffect(() => {
		formOpen ? setDisplay('inline-flex') : setDisplay('none');
	}, [formOpen]);
	const initialValues = {
		id: '',
		name: '',
		email: '',
		country: '',
		date: new Date(),
		firstName: '',
	};

	const validationSchema = Yup.object().shape({
		name: Yup.string().required('Required'),
		email: Yup.string().email('Invalid email address').required('Required'),
		country: Yup.string().required('Required'),
	});

	const handleFormSubmit = async (interestForm: InterestFormData) => {
		let newInterestForm = {
			...interestForm,
			id: uuid(),
			firstName: interestForm.name.split(' ').slice(0, 1).join(' '),
		};
		try {
			await agent.Interest.registerInterest({
				id: newInterestForm.id,
				country: newInterestForm.country,
				date: newInterestForm.date,
				email: newInterestForm.email,
				name: newInterestForm.name,
				firstName: newInterestForm.firstName,
			})
				.then((res) => {
					//setLoading(true);
					//setApplyButtonText('');
					if (res! === 200) {
						setApplyButtonText('Interest Registered');
						setSubmitButtonText('Submitted');
						setName(newInterestForm.firstName);
						setShowSuccess(true);
					} else if (res! !== 200) {
						setApplyButtonText('Try Again');
						setShowError(true);
					}
					setLoading(false);
				})
				.then(() => {
					setFormOpen(false);
				})
				.catch((error) => {
					//console.log(error);
					setApplyButtonText('Try Again');
					setSubmitButtonText('Submit');
					setFormOpen(true);
					setShowError(true);
					setLoading(false);
				});
		} catch (error) {
			//console.log('error, ', error);
			setSubmitButtonText('Submit');
			setApplyButtonText('Try Again');
			setShowError(true);
			setLoading(false);
		}
	};

	return (
		<div className='formAndButtonContainer'>
			<div
				className='openFormButton'
				onClick={() => {
					setFormOpen(!formOpen);
				}}
			>
				<div className={borderClassname}></div>
				<Button
					loading={loading}
					type='button'
					className='styleButton'
					id='openFormButton'
				>
					{applyButtonText}
				</Button>
			</div>
			{showSuccessModal ? <SuccessModal name={name} /> : null}
			{showErrorModal ? <ErrorModal /> : null}

			<div className='formWrapper' style={{ display: display }}>
				<Formik
					validationSchema={validationSchema}
					enableReinitialize
					initialValues={initialValues}
					onSubmit={(values) => handleFormSubmit(values)}
				>
					{({ handleSubmit, isValid, isSubmitting, dirty }) => (
						<Form className='baseForm' onSubmit={handleSubmit} noValidate>
							<MyTextInput
								name='name'
								placeholder='name'
								key='name'
								type='name'
							/>
							<MyTextInput
								name='email'
								placeholder='email'
								key='email'
								type='name'
							/>
							<MySelectInput
								name='country'
								placeholder={placeholders['country']}
								options={countryOption}
							/>
							<Button
								disabled={isSubmitting || !dirty || !isValid}
								loading={isSubmitting}
								size='large'
								color='teal'
								className='submitButton'
								type='submit'
								content={submitButtonText}
							/>
						</Form>
					)}
				</Formik>
			</div>
		</div>
	);
};

export default observer(InterestForm);
