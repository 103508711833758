import './HeroBlock.Style.css';
import { accent3 } from '../../../../app/styles/colorConstants';
import { useStore } from '../../../../app/stores/store';
import InterestForm from '../interestForm/InterestForm';
import { observer } from 'mobx-react-lite';

const HeroBlock = () => {
	const { commonStore } = useStore();
	const { formOpen } = commonStore;

	const pStyles = formOpen ? { height: '14vh', fontSize: '80%' } : {};
	return (
		<div className='heroImage'>
			<div className='heroContainer'>
				<div className='heroTextContainer'>
					<p style={pStyles} className='heroTitleText'>
						Equipping Students for revival
					</p>
				</div>

				<div className='heroButtonAndLine'>
					<InterestForm />
					<div
						className='heroLine'
						style={{ background: accent3, visibility: formOpen ? 'hidden': 'visible'	}}
					/>
				</div>
			</div>
		</div>
	);
};
export default observer(HeroBlock);
