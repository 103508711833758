import { useField } from 'formik';
import { Form, Label } from 'semantic-ui-react';
import './MytextInput.style.css';

interface Props {
	placeholder: string;
	name: string;
	type?: string;
	label?: string;
}

export default function MyTextInput(props: Props) {
	const [field, meta] = useField(props.name);
	return (
		<Form.Field className='inputFieldWrapper' error={meta.touched && !!meta.error}>
			<label>{props.label}</label>
			<input className={props.name + 'Styling inputField'} {...field} {...props} />
			{meta.touched && meta.error ? (
				<Label basic color='red'>
					{meta.error}
				</Label>
			) : null}
		</Form.Field>
	);
}
