import axios, { AxiosError, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { history } from '../..';
import { CoreValues } from '../models/coreValues';
import { CourseOutline } from '../models/courseOutline';
import { InterestFormData } from '../models/interestForm';
import { store } from '../stores/store';

const sleep = (delay: number) => {
	return new Promise((resolve) => {
		setTimeout(resolve, delay);
	});
};
// axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.response.use(
	async (response) => {
		if (process.env.NODE_ENV === 'development') await sleep(1000);
		return response;
	},
	(error: AxiosError | any) => {
		// var _a = error.response,
		// 	data = _a.data,
		// 	status = _a.status,
		// 	config = _a.config,
		// 	headers = _a.headers;
		const { data, status, config, headers } = error.response!;
		switch (status) {
			case 200:
				alert('woohoo');
				//console.log('200');
				break;
			case 400:
				if (typeof data === 'string') {
					toast.error(data);
				}
				if (config.method === 'get' && data.errors.hasOwnProperty('id')) {
					history.push('/not-found');
				}
				if (data.errors) {
					const modalStateErrors = [];
					for (const key in data.errors) {
						if (data.errors[key]) {
							modalStateErrors.push(data.errors[key]);
						}
					}
					throw modalStateErrors.flat();
				}
				break;
			case 401:
				toast.error('unauthorised');
				toast.error('Session expired - please login again');
				break;
			case 404:
				history.push('/not-found');
				break;
			case 500:
				store.commonStore.setServerError(data);
				history.push('/server-error');
				toast.error('server-error', data);
				break;
		}
		return Promise.reject(error);
	},
);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;

const requests = {
	get: <T>(url: string) => axios.get<T>(url).then(responseBody),
	post: <T>(url: string, body: {}) =>
		axios.post<T>(url, body).then((responseBody) => {
			//console.log(responseBody);
			return responseBody.status;
		}),
	put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
	del: <T>(url: string) => axios.delete<T>(url).then(responseBody),
};

const Interest = {
	interestEmail: (
		data: InterestFormData, //console.log('interestEmail',data),
	) => requests.post('/UserInterest/InterestEmailServices', data),
	courseApplication: (data: any) => requests.post('/UserInterest/apply', data),
	registerInterest: (data: InterestFormData) =>
		requests.post('/EmailSender/RegisterInterest', data),
};
const CoreData = {
	coreValues: () => requests.get<CoreValues[]>('/CoreData/corevalues'),
	courseOutline: () => requests.get<CourseOutline[]>('/CoreData/courseoutline'),
};

const agent = {
	Interest,
	CoreData,
};

export default agent;
