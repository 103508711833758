import CoreValueDisplay from '../../../features/components/homeComps/coreValues/CoreValues';
import HeroBlock from '../../../features/components/homeComps/heroBlock/HeroBlock';
import WhoAreWe from '../../../features/components/homeComps/textSectionsFolder/whoAreWe/whoAreWe';
import LeadersBlock from '../../../features/components/homeComps/textSectionsFolder/leaders/LeadersBlock';
import InterestForm from '../../../features/components/homeComps/interestForm/InterestForm';
import CourseOutline from '../../../features/components/homeComps/courseOutlineComps/CourseOutline';
import ResultsText from '../../../features/components/homeComps/textSectionsFolder/resultsText/ResultsText';
import image1 from '../../../assets/images/Image1.jpg';
import image2 from '../../../assets/images/Image2.jpg';
import image3 from '../../../assets/images/Image3.jpg';
import './home.style.css';
import FullWidthBackgroundImage from '../../../features/components/backgroundImages/BackGroundImage';
import { useStore } from '../../stores/store';
import { useEffect } from 'react';
import Header from '../../../features/components/header/Header';

const Home = () => {
	const { coreDataStore } = useStore();

	// useEffect(() => {
	// 	coreDataStore.loadCourseOutline();
	// 	coreDataStore.loadCoreValues();
	// }, [coreDataStore]);

	return (
		<div>
			<Header />
			<HeroBlock />
			<WhoAreWe />
			<div className='coreValueContainer'>
				<div className='cvLine'></div>
				{CoreValueDisplay()}
			</div>
			{FullWidthBackgroundImage(image1)}
			<LeadersBlock />
			{FullWidthBackgroundImage(image2)}
			{/* <WhatToExpect /> */}
			<CourseOutline />
			{FullWidthBackgroundImage(image3)}
			<ResultsText />
			<InterestForm borderLine='buttonLineTopLarge' />
		</div>
	);
};
export default Home;
