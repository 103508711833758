import './Footer.style.css';
import logo from '../../../assets/newlogos/JE-Logo-01.svg';
const Footer = () => {
	return (
		<div className='footerContainer'>
			<img className='footerImage' src={logo} alt='Journey Equip' />
			<a className='footerContactLink' href='mailto:hello@journeyequip.com' title='Email Journey Equip'>Contact Us</a>
			<p className='footerCopyright'>Journey Equip 2022</p>
		</div>
	);
};
export default Footer;
