import './CoreValues.style.css';
import journeyCV from '../../../../app/valuesData/journeyCoreValues.json';
import CoreValueItem from './CoreValueItem';

const CoreValueDisplay = () => {
	// const { coreDataStore } = useStore();
	// const { coreValues, loadCoreValues } = coreDataStore;

	// useEffect(() => {
	// 	loadCoreValues()
	// }, [loadCoreValues]);

	return (
		<>
			{journeyCV.map((cv, index) => (
				<div key={index} className='cv'>
					<CoreValueItem coreV={cv} />
				</div>
			))}
			;
		</>
	);
};

export default CoreValueDisplay;
