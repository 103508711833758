import { createContext, useContext } from 'react';
import CommonStore from './commonStore';
import CoreDataStore from './coreDataStore';

interface Store {
	commonStore: CommonStore;
	coreDataStore: CoreDataStore;
}

export const store: Store = {
	commonStore: new CommonStore(),
	coreDataStore: new CoreDataStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
	return useContext(StoreContext);
}
