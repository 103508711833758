import { observer } from 'mobx-react-lite';
import { useStore } from '../../../../../app/stores/store';
import './whoAreWe.style.css';

const WhoAreWe = () => {
	const { commonStore } = useStore();
	const { formOpen } = commonStore;

	return (
			<div className='whoAreWeContainer'>
				<div
					className='whoAreWeLine'
					style={{ visibility: formOpen ? 'hidden' : 'visible' }}
				/>
				<p className='whoAreWeParagraph' id='aboutUsSection'>
					Journey Equip is a new ministry school run by Journey Community Church
					located in Antrim, Northern Ireland.
					<br />
					<br />
					Our goal is to equip, train and empower students to bring revival to
					Ireland, Europe and The Nations.
				</p>
			</div>
	);
};

export default observer(WhoAreWe);
